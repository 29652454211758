<template>
  <section class="hero is-fullheight has-background-light">
    <div class="hero-head"></div>
    <div class="hero-body">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-8-tablet is-5-desktop">
            <form class="box has-text-centered" @submit.prevent>
              <div class="field">
                <img :src="computedURL()" alt="splashURL" />
              </div>
              <div class="field">
                <h1 class="title is-4">{{ instanceConfig.header }}</h1>
              </div>
              <p
                v-if="$route.query.sessionTimeout"
                class="notification is-warning"
              >
                Session Timed Out
              </p>
              <p v-show="errorFlag" class="notification is-danger">
                <button class="delete" @click="clearError"></button>
                {{ errorMessage }}
              </p>
              <ValidationObserver slim v-slot="{ invalid }">
                <ValidationProvider rules="required" tag="div" class="field">
                  <div class="control has-icons-left">
                    <input
                      class="input"
                      type="text"
                      :placeholder="instanceConfig.usernamePlaceholder"
                      v-model="username"
                    />
                    <span class="icon is-small is-left">
                      <font-awesome-icon icon="user" />
                    </span>
                  </div>
                </ValidationProvider>
                <ValidationProvider rules="required" tag="div" class="field">
                  <div class="control has-icons-left">
                    <input
                      class="input"
                      type="password"
                      :placeholder="instanceConfig.passwordPlaceholder"
                      v-model="password"
                      @keyup.enter="login"
                    />
                    <span class="icon is-small is-left">
                      <font-awesome-icon icon="lock" />
                    </span>
                  </div>
                </ValidationProvider>
                <div class="field">
                  <div class="buttons is-centered">
                    <button
                      :disabled="loadingFlag || invalid"
                      class="button is-link"
                      @click="login"
                    >
                      Login
                    </button>
                  </div>
                </div>
                <div class="field">
                  <p class="content">
                    {{ instanceConfig.regTextMessage }}
                    <router-link :to="{ name: 'registrationForm' }"
                      >Register here</router-link
                    >
                  </p>
                </div>
                <div class="field">
                  <p class="content">
                    <router-link :to="{ name: 'contact' }"
                      >Forgot username/password?</router-link
                    >
                  </p>
                </div>

                <hr />
                <div class="field" v-if="instanceConfig.loginMessage">
                  <p class="login-message">
                    {{ instanceConfig.loginMessage }}
                  </p>
                </div>
                <div class="field">
                  <p class="company-notice">
                    {{ instanceConfig.footer2 }}
                  </p>
                </div>
                <progress
                  v-show="loadingFlag"
                  class="progress is-small is-info"
                  max="100"
                  >15%</progress
                >
              </ValidationObserver>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="hero-foot"></div>
  </section>
</template>

<script>
import axios from "axios";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { mapState, mapActions } from "vuex";
import setCredentials from "@/activities/SetCredentials";

extend("required", {
  ...required,
  message: "This field is required"
});

export default {
  components: { ValidationProvider, ValidationObserver },
  data() {
    return {
      username: null,
      password: null,
      loadingFlag: false,
      errorFlag: false,
      errorMessage: ""
    };
  },
  computed: {
    ...mapState(["instanceConfig"]),
    computedURL() {
      return !this.instanceConfig.splashURL
        ? () => require("../assets/defaultGray.png")
        : () => {
            return this.instanceConfig.splashURL;
          };
    }
  },
  methods: {
    ...mapActions(["fetchPaymentPortalInstanceConfig"]),
    clearError() {
      this.errorFlag = false;
      this.errorMessage = "";
    },
    async login() {
      this.loadingFlag = true;
      this.errorMessage = false;
      this.errorMessage = "";
      try {
        let body =
          "username=" +
          this.username.toLowerCase() +
          "&password=" +
          encodeURIComponent(this.password) +
          "&instanceId=" +
          encodeURIComponent(this.$route.params.instanceId);

        let { data } = await axios.post(
          process.env.VUE_APP_API_URL + "otp/login",
          body,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded"
            }
          }
        );
        if (data.mfaEnabled) {
          sessionStorage.setItem("otp_info", JSON.stringify(data))
          this.$store.commit("SET_USERNAME", this.username.toLowerCase());
          if (data.mfaEnrolled) {
            this.$router.push({ name: "otpRequest" });
          } else {
            this.$router.push({ name: "otpEnrollment" });
          }
        } else {
          let payload = {
            token: data.token,
            username: this.username.toLowerCase()
          };

          await setCredentials(payload);

          this.$router.push({ name: "home" });
        }
      } catch (e) {
        this.errorFlag = true;
        this.errorMessage = "Invalid username or password"; // assume 401
      } finally {
        this.loadingFlag = false;
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm =>
      vm.fetchPaymentPortalInstanceConfig(vm.$route.params.instanceId)
    );
  }
};
</script>

<style lang="scss">
html,
body,
#wrapper {
  height: 100%;
}

.login-message {
  font-size: 0.8rem;
}

.company-notice {
  font-size: 0.6rem;
}
</style>
