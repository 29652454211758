import axios from "axios";
import store from "../store/index";

export default async function({ username, token }) {
  /**
   * Includes main account(s) lookup
   */

  localStorage.setItem("username", username);
  store.commit("SET_USERNAME", username);

  try {
    /**
     * first call gets the user fields like firstName
     */
    let response1 = await axios.get(
      process.env.VUE_APP_API_URL + "api/demographics/m;user=" + username,
      {
        headers: {
          Authorization: "Bearer " + token
        }
      }
    );

    /**
     * second call gets the list of accounts
     */
    let response2 = await axios.get(
      process.env.VUE_APP_API_URL + "api/demographics/listM;user=" + username,
      {
        headers: {
          Authorization: "Bearer " + token
        }
      }
    );

    localStorage.setItem("jwt", token);
    store.commit("SET_JWT", token);
    store.commit("SET_USER_INFO", response1.data);
    store.commit("accounts/SET_ACCOUNTS", response2.data);
  } catch (e) {
    throw "Account not found";
  }
}
